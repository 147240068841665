export enum PlacesActions {
	PLACES_LIST_REQUEST = "PLACES_LIST_REQUEST",
	PLACES_LIST_SUCCESS = "PLACES_LIST_SUCCESS",
	PLACES_LIST_FAIL = "PLACES_LIST_FAIL",
	FILTER_PLACES = "FILTER_PLACES",
	SET_SELECTED_PLACE = "SET_SELECTED_PLACE",
	SET_WAITING_LOCATION = "SET_WAITING_LOCATION",
	SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",
	RESET_NEAREST_PLACES = "RESET_NEAREST_PLACES",
	FILTER_NEAREST_PLACES = "FILTER_NEAREST_PLACES",
	SET_IS_DISTANCE_BY_MAP_CENTER = "SET_IS_DISTANCE_BY_MAP_CENTER",
	SET_NEAREST_PLACES = "SET_NEAREST_PLACES",
}
