import { useContext, useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { DomEvent } from "leaflet";
import { useTranslation } from "react-i18next";
import "leaflet-geosearch/dist/geosearch.css";

import { PlacesState } from "state/types";
import { defaultPosition } from "constants/geo";
import { mapboxToken } from "constants/api";

import Language from "./Language";
import Filter from "./Filter";
import SearchControl from "./Search/SearchControl";
import Details from "../Details";
import Places from "./Places";
import Legal from "./Legal";
import { PlacesStateContext } from "PlacesContext";
import "./Map.css";
import Contribute from "./Contribute";
import LocateMeButton from "./Controls/LocateMeButton";
import PlaceList from "components/Map/PlaceList";
import CloseIcon from "icons/Close";

const Map = () => {
	const { selectedPlace, nearestPlaces } = useContext(
		PlacesStateContext
	) as PlacesState;
	const { t } = useTranslation();

	// handle PlaceList open/close
	const [isOpenPlaceList, setIsOpenPlaceList] = useState(true);
	const [showPauseMessage, setShowPauseMessage] = useState(true);

	const pauseRef = useRef(null);

	const displayNearestPlaces =
		nearestPlaces && nearestPlaces?.length && !selectedPlace;

	// handle margin-bottom of zoom control
	const getZoomControlNarginBottom = () => {
		if (displayNearestPlaces) {
			if (isOpenPlaceList) {
				if (nearestPlaces?.length === 1) {
					return "margin-bottom-one-items";
				} else if (nearestPlaces?.length >= 2) {
					return "margin-bottom-two-items";
				}
			} else {
				return "margin-bottom-zero-items";
			}
		}
		return "";
	};

	// Fix for the issue that trying to scroll the search results zooms the map
	useEffect(() => {
		if (pauseRef.current) {
			DomEvent.disableClickPropagation(pauseRef.current);
			DomEvent.disableScrollPropagation(pauseRef.current);
		}
	}, [pauseRef.current]);

	return (
		<div className={`map__container ${getZoomControlNarginBottom()}`}>
			<div className="TitleBarMobile">{t("BarrierfreeToilets")}</div>
			<MapContainer
				center={defaultPosition}
				zoom={25}
				scrollWheelZoom={true}
				style={{ height: "100%" }}
				zoomControl={false}
				attributionControl={false}
			>
				<TileLayer
					url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`}
					tileSize={512}
					zoomOffset={-1}
				/>
				<SearchControl />
				<Filter />
				<Language />
				<LocateMeButton displayNearestPlaces={displayNearestPlaces} />
				<ZoomControl
					position="bottomright"
					zoomInText="+"
					zoomOutText="-"
				/>
				<Places />
				<Contribute />
				{displayNearestPlaces && (
					<PlaceList
						isOpen={isOpenPlaceList}
						setIsOpen={setIsOpenPlaceList}
					/>
				)}
				{showPauseMessage && (
					<div className="Pause" ref={pauseRef}>
						<div className="row gap align-start">
							<h3 className="grow">
								Das Projekt Inclus muss Pause machen
							</h3>
							<button
								type="button"
								className="icon-only"
								onClick={() => setShowPauseMessage(false)}
							>
								<CloseIcon />
							</button>
						</div>

						<p>
							Hier finden Sie barrierefreie Toiletten in München:
						</p>
						<p>
							<a
								href="https://geoportal.muenchen.de/portal/wc_finder/"
								target="_blank"
								rel="noopener noreferrer"
							>
								WC Finder der Stadt München
							</a>
						</p>
						<p>
							<a
								href="https://www.cbf-muenchen.de/barrierefreie-orte/rolli-toiletten"
								target="_blank"
								rel="noopener noreferrer"
							>
								Webseite des CBF München e.V.
							</a>
						</p>
						<p>
							<a
								href="https://www.toiletten-fuer-alle.de/wo-wie/nc.html"
								target="_blank"
								rel="noopener noreferrer"
							>
								Webseite &quot;Toiletten für Alle&quot;
							</a>
						</p>
						<p>
							<a
								href="https://wheelmap.org/search"
								target="_blank"
								rel="noopener noreferrer"
							>
								Wheelmap
							</a>
						</p>

						<br />
						<details>
							<summary>Warum macht Inclus Pause?</summary>
							Die Nutzungsrechte für die Daten wurden uns leider
							nicht verlängert und eine leere Karte hilft einfach
							niemandem.
						</details>
						<details>
							<summary>Wie könnte es weiter gehen?</summary>
							Wir suchen gemeinsam mit dem Behindertenbeauftragten
							der Stadt München und dem Koordinierungsbüro zur
							Umsetzung der UN-BRK nach einer Lösung für den
							Fortbestand von Inclus. Mit neuen Förderern können
							wir versuchen, die ausgelaufene Kooperation wieder
							neu zu starten. Denkbar ist auch ein
							Community-Modell, bei dem die Sammlung der Daten von
							Freiwilligen übernommen wird. Eine Art Wikipedia
							bzw. Open Street Maps für barrierefreie Toiletten.
						</details>
						<br />
						<p className="small">
							Wenn Ihnen Inclus in den letzten Monaten
							weitergeholfen hat, würden wir uns über eine kurze
							Rückmeldung per Mail freuen →{" "}
							<a href="mailto:inclus@dpschool.io">
								inclus@dpschool.io
							</a>
						</p>
					</div>
				)}
			</MapContainer>
			<Legal />
			{selectedPlace && <Details />}
		</div>
	);
};

export default Map;
