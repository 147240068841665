import { useNavigate } from "react-router";
import { Widget } from "@typeform/embed-react";

import "./Forms.css";

const AddInformation = () => {
	const navigate = useNavigate();
	const navigateBack = () => navigate("/");

	return (
		<Widget
			id="ZXojgSlL"
			className="Form embedded"
			onEndingButtonClick={navigateBack}
			onClose={navigateBack}
			inlineOnMobile
		/>
	);
};

export default AddInformation;
